<template>
  <!-- eslint-disable -->
  <div>
    <transition name="slide">
      <div v-if="window.width >= 800 || sideMenu" class="sidenav-main">
        <div style="height: 80px; position: relative;">
          <img
            :src="'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+prestador.id+'/images/logo.png'"
            style="max-height:60px; max-width: 200px; z-index:100003; top:50%; position: absolute; transform: translateY(-50%); left:20px"
            alt="Imagen"
          />
        </div>
        <div class="main-scrollable">
          <aside
            id="layout-menu"
            style="padding-bottom:40px; min-height: 100vh;"
            class="menu-vertical menu bg-menu-theme"
          >
            <div class="menu-inner-shadow"></div>
            <ul class="menu-inner py-1">
              <!-- Menú -->
              <li
                v-for="m in menu_items"
                :key="m.nombre"
                v-if="hasPermissions(m)"
                @click="() => { if (m.to != undefined){ $router.push({name: m.to}) } else { m.viendo = !m.viendo } }"
                :class="{
                  'active': $route.name == m.nombre || m.nombres_activos.includes($route.name),
                  'open': m.viendo,
                  'menu-item': !m.seccion,
                  'menu-header small text-uppercase': m.seccion,
                }"
              >
                <template>
                  <a
                    v-if="!m.seccion"
                    class="menu-link"
                    :class="{ 'active': $route.name == m.nombre, 'menu-toggle': m.subrutas != undefined }"
                    :style="{
                      color: $route.name == m.nombre || m.nombres_activos.includes($route.name) ? 'white !important' : '',
                      background: $route.name == m.nombre || m.nombres_activos.includes($route.name)
                        ? 'linear-gradient(to right, ' + prestador.admin.assets.colors.primary + ', ' + prestador.admin.assets.colors.secondary + ')'
                        : '',
                    }"
                  >
                    <i :class="m.icono" class="menu-icon tf-icons ti"></i>
                    <div data-i18n="Dashboards">{{ m.nombre }}</div>
                  </a>
                  <span v-else class="menu-header-text">{{ m.nombre }}</span>
                </template>
                <ul v-if="m.viendo" class="menu-sub">
                  <li
                    v-for="sub in m.subrutas"
                    :key="sub.nombre"
                    v-if="hasPermissions(sub)"
                    class="menu-item"
                  >
                    <div
                      @click="() => { if (sub.to != undefined){ $router.push({name: sub.to}) } }"
                      class="menu-link"
                    >
                      <div>{{ sub.nombre }}</div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </aside>
        </div>
      </div>
    </transition>
    <div
      @click="CambiarMenuAction({})"
      v-if="window.width < 800 && sideMenu"
      style="position:fixed; width:100vw; height:100vh; background:rgba(0,0,0,0.4); z-index:9999;"
    ></div>
  </div>
</template>

<script>
/* Core CSS */
import '@/assets/estilo_importado/vendor/css/rtl/core.css';
import '@/assets/estilo_importado/vendor/css/rtl/theme-default.css';
import '@/assets/estilo_importado/css/demo.css';

/* Vendor css */
import '@/assets/estilo_importado/vendor/libs/node-waves/node-waves.css';
import '@/assets/estilo_importado/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import '@/assets/estilo_importado/vendor/libs/typeahead-js/typeahead.css';

/* Icons */
import '@/assets/estilo_importado/vendor/fonts/fontawesome.css';
import '@/assets/estilo_importado/vendor/fonts/tabler-icons.css';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SideNav',
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      menu_items: [
        { nombre: 'General', seccion: true, nombres_activos: []},
        { nombre: 'Inicio', icono: 'ti-smart-home', to: 'Inicio', nombres_activos: [] },
        {
          nombre: 'Órdenes',
          icono: 'ti-shopping-cart',
          to: 'Ordenes',
          permissions: ['permiso_general'],
          nombres_activos: ['Ordenes'],
        },
        {
          nombre: 'Profesionales',
          icono: 'ti-users',
          to: 'Profesionales',
          nombres_activos: ['Profesional'],
          permissions: ['gestionar_profesionales'],
        },
        { nombre: 'Gráficos y mapas', seccion: true, nombres_activos: [], permissions: ['gestionar_profesionales']},
        {
          nombre: 'Ubicación tomadores',
          icono: 'ti-map',
          to: 'Ubicacion',
          nombres_activos: ['Ubicacion'],
          permissions: ['gestionar_profesionales'],
        },
        {
          nombre: 'Reportes',
          icono: 'ti-chart-pie',
          viendo: false,
          permissions: ['ver_reportes'],
          subrutas: [{ nombre: 'Gráficos', to: 'Graficos' }],
          nombres_activos: ['Graficos', 'Descargas'],
        },
        {
          nombre: 'Configuración',
          seccion: true,
          nombres_activos: [],
          permissions: ['acceso_configuracion'],
        },
        {
          nombre: 'Prestaciones',
          icono: 'ti-file-description',
          to: 'MaestroExamenes',
          permissions: ['gestionar_prestaciones'],
          nombres_activos: ['MaestroExamenes'],
        },
        {
          nombre: 'Aseguradoras',
          icono: 'ti-file-dollar',
          to: 'Aseguradoras',
          permissions: ['gestionar_aseguradoras'],
          nombres_activos: [],
        },
        {
          nombre: 'Códigos Descuento',
          icono: 'ti-id',
          to: 'CodigoDescuentos',
          permissions: ['gestionar_codigos_descuento'],
          nombres_activos: ['CodigoDescuentos'],
        },
        {
          nombre: 'Otras Configuraciones',
          icono: 'ti-settings',
          permissions: ['otras_configuraciones'],
          viendo: false,
          subrutas: [
            {
              nombre: 'Costos Servicios',
              to: 'CostosServicios',
              permissions: ['costos_servicios'],
            },
            {
              nombre: 'Precio por comuna',
              to: 'ModificarPrecioExamenComuna',
              permissions: ['precio_por_comuna'],
            },
          ],
          nombres_activos: ['CostosServicios', 'ModificarPrecioExamenComuna'],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['admin_datos', 'isLoggedIn', 'sideMenu', 'prestador']),
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions(['CambiarMenuAction']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    hasPermissions(item) {
      if (!item.permissions) return true;
      return item.permissions.every((permiso) => this.admin_datos.permissions.includes(permiso));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-header-text{
  padding-left: 25px;
}
.menu-sub{
  display: inline;
}
.menu-item.active > .menu-link{
  background-color: red in !important;
}
.miniarrow{
  float:right;
  margin-right:20px;
  transition-duration: 0.25s;
}
.miniarrowActive{
  float:right;
  margin-right:20px;
  color:white;
  transform:rotate(90deg);
  transition-duration: 0.25s;
}

.liclicked{
  background-color: rgb(29, 37, 48) !important;
  color:white !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
 /*  background: rgb(38, 53, 67); */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(240, 240, 240);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(240, 240, 240);
}

.arrows{
  font-size: 20px;
  margin-right:10px;
  transform: translate(0px, 3px);
}
.subul{
  padding:0px;
}
.main-scrollable{
  overflow-y: scroll;
  height:100%;
  padding-right: 10px;

  position:relative;
  /*  background-color: rgb(38, 53, 67); */
  z-index: 100;
}

.subitems{
  /* background-color: rgb(34, 45, 58) !important; */
  overflow: hidden; 
  border-left: 4px solid white;
}
.subitems ul{
  /* background-color: rgb(34, 45, 58) !important; */
}
.subitems li{
  background-color: rgb(34, 45, 58) !important;
  padding:10px 0px 10px 20px !important;

}
.isActive{
  max-height:450px;
  transition: max-height 0.25s ease-in;
}
.isInActive{
  max-height:0px;
  padding:0px;
  transition: max-height 0.15s ease-out;
}

.icons{
  margin: 0px 10px 0px 0px;
  font-size: 20px;
}
.sidenav-main{
  width:265px;
  height:100%;
  background-color: white;
  text-align: left;
  /* background-color: rgb(38, 53, 67); */
  top:0px;
  position:fixed;
  z-index: 99999;

  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3)
 


}
.nav-label{
  color: white;
  font-size: 12px;
  padding: 0px 0px 0px 14px;
  /*  background-color: rgb(38, 53, 67); */
}
.sidenav-main ul{
  padding: 10px 0px 10px 0px;
  list-style-type: none;
  color: rgba(255,255,255,0.5);
  /* background-color: rgb(38, 53, 67); */
  font-size: 14px;
}
.sidenav-main li:hover{


/*   color: rgba(255,255,255,1); */

}

.sidenav-main li{
  padding: 10px 0px 10px 20px;
  cursor: pointer;
  /* background-color: rgb(38, 53, 67); */
  
}
h3 {
  margin: 40px 0 0;
}

a {
  color: #42b983;
}
.slide-enter-active,
.slide-leave-active {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-name: slide;
}

.slide-leave-active {
  animation-direction: reverse;
}

@keyframes slide {
  from {
    opacity: 1;
    transform: translateX(-300px);
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .sidenav-main {
    position: fixed !important;
    top:0px;
    left:0px;
    
    max-width:260px;
  }

}


</style>
