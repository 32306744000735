<template>
  <!-- eslint-disable -->
  <div style="text-align:left; background-color: rgb(242, 247, 251);">
    
    <div class="column-left">
      <SideNav />
    </div>
  
    <div class="column-right">
      <TopNav />
      <div class="main-container">   
        
        <div v-show="loaded" class="articulo-principal">
          <h2>Prestaciones</h2>

          <div class="boxy">
            <span style="font-style: italic;">
              *Arrastra con el cursor para ver todas las columnas. Puedes <b>modificar la configuración</b> de cada prestación utilizando el lápiz de la derecha.
            </span>
            <div class="container-fluid">
              <div>
                <div class="flex-header">
                  <div class="flex-header-left">
                    <!-- You can add content here if needed -->
                  </div>
                  <div class="flex-header-right">
                    <div style="text-align:right; padding:7px;">
                      <button
                        class="btn-ir-gordo"
                        @click="agregar_examen()"
                        :style="{'background-color':prestador.admin.assets.colors.primary}"
                      >
                        Agregar Examen <b-icon icon="plus-circle" />
                      </button>

                      <div style="display: inline-block; margin-left:5px;">
                        <excel-uploader />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="scroll-div">
                  <table>
                    <thead>
                      <tr>
                        <th @click="ordenar_por('id')" style="text-align:center;">
                          Id
                          <b-icon 
                            v-if="ordenar_por_selected == 'id'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-down">
                          </b-icon>
                          <b-icon 
                            v-else-if="ordenar_por_selected == 'idr'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-up">
                          </b-icon>
                        </th>
                        <th @click="ordenar_por('nombre')">Nombre
                          <b-icon 
                            v-if="ordenar_por_selected == 'nombre'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-down">
                          </b-icon>
                          <b-icon 
                            v-else-if="ordenar_por_selected == 'nombrer'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-up">
                          </b-icon>
                        </th>
                        <th @click="ordenar_por('categoria')">Categoría
                          <b-icon 
                            v-if="ordenar_por_selected == 'categoria'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-down">
                          </b-icon>
                          <b-icon 
                            v-else-if="ordenar_por_selected == 'categoriar'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-up">
                          </b-icon>
                        </th>
                        <th>Requiere receta</th>
                        <th>Requiere pago inmediato</th>
                        <th>Información</th>
                        <th>Código</th>
                        <th>Keywords</th>
                        <th @click="ordenar_por('precio')">Precio
                          <b-icon 
                            v-if="ordenar_por_selected == 'precio'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-down">
                          </b-icon>
                          <b-icon 
                            v-else-if="ordenar_por_selected == 'precior'" 
                            style="position: absolute; transform: translate(1px, 3px);" 
                            icon="chevron-up">
                          </b-icon>
                        </th>
                        <th>Activo</th>
                        <th>Sug</th>
                        <th>Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="e in editando_examenes"
                        :key="e.id"
                        style="height:40px; background-color:white;"
                      >
                        <template v-if="!e.modificar">
                          <td style="text-align:center;">{{ e.id }}</td>
                          <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                            {{ e.nombre }}
                          </td>
                          <td style="max-width:200px;">
                            {{ categorias.find(cat => cat.id === e.categoria).nombre || 'N/A' }}
                          </td>
                          <td style="max-width:200px;">
                            {{ e.requiere_orden == 0 ? 'No' : 'Si' }}
                          </td>
                          <td style="max-width:200px;">
                            {{ e.requiere_pago == 0 ? 'No' : 'Si' }}
                          </td>
                          <td>{{ e.informacion }}</td>
                          <td>{{ e.codigo }}</td>
                          <td>{{ e.keywords }}</td>
                          <td style="width:80px;">{{ e.precio }}</td>
                          <td>{{ e.activo ? 'Si' : 'No' }}</td>
                          <td>{{ e.hasta == 1 ? 'Si' : 'No' }}</td>
                          <td>
                            <span
                              style="color:blue; cursor:pointer; margin-right:6px;"
                              @click="e.modificar = true"
                            >
                              <i 
                                :style="{'color': prestador.admin.assets.colors.primary}" 
                                class="menu-icon tf-icons ti icon-edit ti-pencil"
                              ></i>
                            </span>  
                            
                            <span
                              style="color:red; cursor:pointer;"
                              @click="remover_examen(e.id)"
                            >
                              <i 
                                :style="{'color': '#FF4136'}" 
                                class="action-icon tf-icons ti ti-trash">
                              </i>
                            </span>  
                          </td>
                        </template>
    
                        <template v-else>
                          <td>{{ e.id }}</td>
                          <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                            <input
                              v-model="e.enombre"
                              type="text"
                              class="form-control"
                            />
                          </td>
                          <td>
                            <select v-model="e.ecategoria" class="form-control">
                              <option
                                v-for="cat in categorias_usadas"
                                :key="cat.id"
                                :value="cat.id"
                              >
                                {{ cat.nombre }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select v-model="e.erequiere_orden" class="form-control">
                              <option :value="1">Si</option>
                              <option :value="0">No</option>
                            </select>
                          </td>
                          <td>
                            <select v-model="e.erequiere_pago" class="form-control">
                              <option :value="1">Si</option>
                              <option :value="0">No</option>
                            </select>
                          </td>
                          <td>
                            <input
                              v-model="e.einformacion"
                              type="text"
                              class="form-control"
                              style="width:170px;"
                            />
                          </td>
                          <td>
                            <input
                              v-model="e.ecodigo"
                              type="text"
                              class="form-control"
                              style="width:110px;"
                            />
                          </td>
                          <td>
                            <input
                              v-model="e.ekeywords"
                              type="text"
                              class="form-control"
                              style="width:110px;"
                              placeholder="Separados por comas"
                            />
                          </td>
                          <td style="width:80px;">
                            <input
                              v-model.number="e.eprecio"
                              type="number"
                              class="form-control"
                              style="width:70px;"
                              min="0"
                              step="0.01"
                            />
                          </td>
                          <td style="width:80px;">
                            <select v-model="e.edesde" class="form-control">
                              <option :value="true">Si</option>
                              <option :value="false">No</option>
                            </select>
                          </td>
                          <td style="width:80px;">
                            <select v-model="e.ehasta" class="form-control">
                              <option :value="1">Si</option>
                              <option :value="0">No</option>
                            </select>
                          </td>
                          <td style="text-align:center;">
                            <span @click="e.modificar = false; modificar(e.id);"> 
                              <b-icon
                                style="color:green; cursor:pointer;"
                                icon="check-circle"
                              />
                            </span>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div v-show="!loaded" class="articulo-principal">
          <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">
            <Loader :height="100" :width="100" />
          </div>
        </div>
      </div>
    </div>
  
    <!-- Agregar Examen Modal -->
    <b-modal
      id="agregar-examen-modal"
      v-model="showAgregarExamenModal"
      title="Agregar Nuevo Examen"
      @hide="resetNuevoExamen"
      hide-footer
      style=""
    >
      <form @submit.prevent="guardarNuevoExamen">
        <div class="mb-3">
          <label for="nombre" class="form-label">Nombre</label>
          <input
            type="text"
            id="nombre"
            class="form-control"
            v-model="nuevoExamen.nombre"
            required
          />
        </div>
        <div class="mb-3">
          <label for="categoria" class="form-label">Categoría</label>
          <select
            id="categoria"
            class="form-control"
            v-model="nuevoExamen.categoria"
            required
          >
            <option disabled value="">Seleccione una categoría</option>
            <option v-for="cat in categorias" :key="cat.id" :value="cat.id">
              {{ cat.nombre }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="keywords" class="form-label">Keywords</label>
          <input
            type="text"
            id="keywords"
            class="form-control"
            v-model="nuevoExamen.keywords"
            placeholder="Separados por comas"
            required
          />
        </div>
        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-secondary me-2" @click="showAgregarExamenModal = false">
            Cancelar
          </button>
          <button type="submit" class="btn btn-primary">
            Guardar
          </button>
        </div>
      </form>
    </b-modal>
  
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import Fuse from 'fuse.js';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import ExcelUploader from './utils/ExcelUploader.vue';
// Import BootstrapVue components
import { BModal, BIcon } from 'bootstrap-vue';

export default {
  name: 'HelloWorld',
  components: {
    ExcelUploader,
    Loader,
    TopNav,
    SideNav,
    BModal, // Register BModal from BootstrapVue
    BIcon,  // Register BIcon from BootstrapVue
  },
  data() {
    return {
      ordenar_por_selected: 'id',
      loaded: false,
      editando_examenes: [],
      categorias: [],
      editando_categorias: [],
      costos: {
        costo_servicio_base: -1,
        costo_servicio_variable: -1,
        costo_servicio_festivos: -1,
      },
      modificar_costos: false,

      // New Data Properties for Modal
      showAgregarExamenModal: false,
      nuevoExamen: {
        nombre: '',
        categoria: '',
        keywords: '',
      },
    };
  },
  created(){
    this.initialize_();
    this.get_costos_servicio();
  },
  methods: {
    ...mapActions(['recargar']),

    ordenar_por(attributeName) {
      if (this.ordenar_por_selected !== attributeName && this.ordenar_por_selected !== `${attributeName}r`) {
        this.ordenar_por_selected = attributeName;
        return this.editando_examenes.sort((a, b) => {
          if (a[attributeName] < b[attributeName]) {
            return -1;
          }
          if (a[attributeName] > b[attributeName]) {
            return 1;
          }
          return 0;
        });
      }
      this.ordenar_por_selected = attributeName + 'r';
      return this.editando_examenes.sort((a, b) => {
        if (a[attributeName] < b[attributeName]) {
          return 1;
        }
        if (a[attributeName] > b[attributeName]) {
          return -1;
        }
        return 0;
      });
    },

    DownloadExamsExcel(){
      axios({
        url: `${window.hostname}/api/admin/exportar/examenes`,
        method: 'GET',
        responseType: 'blob',
      })
      .then(response => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'examenes.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch(e => {
        console.log(e);
      });
    },

    DownloadExamsExcel2(){
      axios({
        url: `${window.hostname}/api/admin/descargar_excel_estandar`,
        method: 'GET',
        responseType: 'blob',
      })
      .then(response => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exams_with_prestador.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch(e => {
        console.log(e);
      });
    },

    get_costos_servicio(){
      axios.get(`${window.hostname}/api/admin/costo_servicio`)
          .then(response => {
            this.costos = response.data;
          })
          .then(() => {
            console.log(this.costos);
          })
          .catch(e=>{
              console.log("Error al cargar costos servicios...")
          })
    },

    async post_costos_servicio(){
      if (this.modificar_costos) {
        axios.post(`${window.hostname}/api/admin/costo_servicio`, this.costos)
            .then(response => { this.get_costos_servicio(); })
            .catch(e =>{ console.log("Error al cargar...") })
      }
    },

    async remover_examen(id) {
      if (!confirm('¿Está seguro de que desea eliminar este examen?')) {
        return;
      }
      axios.post(`${window.hostname}/api/admin/remove_examen`, { id: id })
          .then(async (response) => 
          {
            await this.recargar({ prestador_id: this.prestador.id })
            this.initialize_();
          })
          .catch(e=>{
              console.log(e)
          })
    },

    agregar_examen(){
      this.abrirAgregarExamenModal();
    },

    abrirAgregarExamenModal(){
      this.showAgregarExamenModal = true;
    },

    resetNuevoExamen(){
      this.nuevoExamen.nombre = '';
      this.nuevoExamen.categoria = '';
      this.nuevoExamen.keywords = '';
    },

    guardarNuevoExamen(){
      const { nombre, categoria, keywords } = this.nuevoExamen;
      if (!nombre.trim() || !categoria || !keywords.trim()) {
        this.$bvToast.toast('Por favor, complete todos los campos.', {
          title: 'Advertencia',
          variant: 'warning',
          solid: true,
        });
        return;
      }

      // Prepare data for API
      const data = {
        nombre: nombre.trim(),
        categoria_id: categoria,
        keywords: keywords.split(',').map(kw => kw.trim()).filter(kw => kw),
      };

      // Send POST request to add new examen
      axios.post(`${window.hostname}/api/admin/crear_examen`, data)
        .then(response => {
          this.$bvToast.toast('Examen agregado exitosamente.', {
            title: 'Éxito',
            variant: 'success',
            solid: true,
          });
          this.showAgregarExamenModal = false;
          this.recargar({ prestador_id: this.prestador.id }).then(() => {
            this.initialize_();
          });
        })
        .catch(error => {
          console.error(error);
          this.$bvToast.toast('Error al agregar el examen.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          });
        });
    },

    modificar(id){
      const data = this.editando_examenes.find(x => x.id === id);
      if (!data) {
        this.$bvToast.toast('Examen no encontrado.', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        });
        return;
      }

      const payload = {
        id: id,
        categoria_id: data.ecategoria,
        precio: parseFloat(data.eprecio),
        desde: data.edesde,
        activo: data.edesde, // Assuming 'activo' is tied to 'desde'
        hasta: data.ehasta,
        sugerido: data.ehasta,
        informacion: data.einformacion.trim(),
        requiere_orden: data.erequiere_orden,
        requiere_pago: data.erequiere_pago,
        nombre: data.enombre.trim(),
        keywords: data.ekeywords.split(',').map(kw => kw.trim()).filter(kw => kw),
        codigo: data.ecodigo.trim(),
      };

      axios.post(`${window.hostname}/api/admin/modificar_examen`, payload)
        .then(response => {
          this.$bvToast.toast('Examen modificado exitosamente.', {
            title: 'Éxito',
            variant: 'success',
            solid: true,
          });
          this.recargar({ prestador_id: this.prestador.id }).then(() => {
            this.initialize_();
          });
        })
        .catch(e => {
          this.loaded = true;
          console.log(e);
          this.$bvToast.toast('Error al modificar el examen.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          });
        });
    },
      
  initialize_(pid){
      this.loaded = false;
      axios.get(window.hostname+'/api/admin/init_modificar_examenes')
      .then(response => 
      {
          try{

         
          this.editando_examenes = []
          this.categorias = response.data.categorias

          for(var i = 0; i <= this.examenes.length; i++){
            this.editando_examenes.push({

                nombre:this.examenes[i].nombre,
                id:this.examenes[i].id,
                precio:this.examenes[i].precio,
                informacion:this.examenes[i].informacion,
                keywords:this.examenes[i].keywords,
                categoria:this.examenes[i].categoria_id,
                requiere_orden:this.examenes[i].requiere_orden,
                requiere_pago:this.examenes[i].requiere_pago,
                desde:this.examenes[i].desde,
                activo:this.examenes[i].activo,
                hasta:this.examenes[i].hasta,
                sugerido:this.examenes[i].hasta,
                codigo:this.examenes[i].codigo_int,
                
                enombre:this.examenes[i].nombre,
                eid:this.examenes[i].id,
                eprecio:this.examenes[i].precio,
                einformacion:this.examenes[i].informacion,
                ekeywords:this.examenes[i].keywords,
                ecategoria:this.examenes[i].categoria_id,
                erequiere_orden:this.examenes[i].requiere_orden,
                erequiere_pago:this.examenes[i].requiere_pago,
                edesde:this.examenes[i].activo,
                ehasta:this.examenes[i].hasta,
                ecodigo:this.examenes[i].codigo,

                modificar:false,
            })
            this.loaded = true;
          }

          } catch(e){
            console.log(e)
            this.loaded = true;
            
          }
      })

      .catch(e=>{
          this.loaded = true;
          console.log("Error al cargar...")
      })

  },
  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'prestador']),
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)
    },
    examenes(){
      this.initialize_()
    }
  },
  mounted() {
    const scrollDiv = document.querySelector('.scroll-div');

    let isDragging = false;
    let lastX;

    scrollDiv.addEventListener('mousedown', (e) => {
        isDragging = true;
        lastX = e.clientX;
        scrollDiv.style.cursor = 'grabbing';
    });

    document.addEventListener('mousemove', (e) => {
        if (!isDragging) return;

        const deltaX = lastX - e.clientX;
        scrollDiv.scrollLeft += deltaX;

        lastX = e.clientX;
    });

    document.addEventListener('mouseup', () => {
        isDragging = false;
        scrollDiv.style.cursor = 'grab';
    });

    this.ENV = process.env;
    this.fComunas = new Fuse(this.comunas, { keys: ['Nombre'] });
  }
}
</script>

<style scoped>

.flex-header{
  display: flex;
  justify-content: space-between;
}
.flex-header-right{
  text-align: right;
}
.flex-header-left{
  display: flex;
  align-items: center;
}
.scroll-div {
  cursor: grab !important;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

table { 
  width: 100%;
  border-collapse: collapse;
}
thead th {
  font-weight: 700;
  padding: 10px;
  position: relative; /* For absolute positioning of icons */
  cursor: pointer;
}
tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}
td {
  text-align: left;
}
.articulo-principal{
  min-height: Calc(100vh - 120px);
  max-width:1390px;
  margin: 0 auto;
}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
.column-right{
  background-color: rgb(242, 247, 251);
  padding-top:0px;
  padding-left:275px;
  width:100%;
}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;
  margin-top:10px;
}
@media (max-width: 800px) {
 .column-right{
  padding-left:0px;
 }
}
</style>
