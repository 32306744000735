<template>
  <!-- eslint-disable -->
  <div style="text-align:left; background-color: rgb(242, 247, 251);">
    <div class="column-left">
      <SideNav />
    </div>

    <div class="column-right">
      <TopNav />
      <div class="main-container">   
        <div v-if="loaded" class="articulo-principal">
          <h2>Costo por servicios</h2>    
          <div class="boxy">
            <table style="width:100%;">
              <thead style="height:40px;">
                <tr>
                  <th>Servicio Domiciliario</th>
                  <th>Valor</th>
                  <th>Editar</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Recargo Base (Por orden)</td>
                  <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                    $<input
                      v-model="costos.costo_servicio_base"
                      type="text"
                      :disabled="!modificar_costos"
                    >
                  </td>
                  <td>
                    <span @click="toggleModificarCostosAndPost"> 
                      <i 
                        :style="{ color: !modificar_costos ? prestador.admin.assets.colors.primary : 'green' }" 
                        :class="{ 'ti-pencil': !modificar_costos, 'ti-square-rounded-check': modificar_costos }" 
                        class="menu-icon tf-icons ti icon-edit"
                      ></i>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Recargo Variable (Por paciente)</td>
                  <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                    $<input
                      v-model="costos.costo_servicio_variable"
                      type="text"
                      :disabled="!modificar_costos"
                    >
                  </td>
                  <td>
                    <span @click="toggleModificarCostosAndPost">
                      <i 
                        :style="{ color: !modificar_costos ? prestador.admin.assets.colors.primary : 'green' }" 
                        :class="{ 'ti-pencil': !modificar_costos, 'ti-square-rounded-check': modificar_costos }" 
                        class="menu-icon tf-icons ti icon-edit"
                      ></i>
                    </span>
                  </td>
                </tr>
                <tr style="height:40px; background-color:white;">
                  <td>Recargo Festivos (Extra por fecha)</td>
                  <td style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                    $<input
                      v-model="costos.costo_servicio_festivos"
                      type="text"
                      :disabled="!modificar_costos"
                    >
                  </td>
                  <td>
                    <span @click="toggleModificarCostosAndPost"> 
                      <i 
                        :style="{ color: !modificar_costos ? prestador.admin.assets.colors.primary : 'green' }" 
                        :class="{ 'ti-pencil': !modificar_costos, 'ti-square-rounded-check': modificar_costos }" 
                        class="menu-icon tf-icons ti icon-edit"
                      ></i>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              class="menuCol btn-ir-gordo"
              :style="{ 'background-color': prestador.admin.assets.colors.primary }"
              @click="$router.push({ name: 'ModificarCostoServicioComuna' })"
            >
              <b-icon icon="pen" /> Modificar Costo por Comuna
            </button>
          </div>
        </div>
        <div v-else class="articulo-principal">
          <h2>Costo por servicios</h2>
          <div class="boxy">
            <Loader :height="100" :width="100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapGetters } from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props: ['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
  },
  data() {
    return {
      loaded: false,
      profesionales: [],
      costos: {
        costo_servicio_base: -1,
        costo_servicio_variable: -1,
        costo_servicio_festivos: -1,
      },
      modificar_costos: false,
    };
  },
  created() {
    this.get_costos_servicio();
  },
  methods: {
    get_costos_servicio() {
      this.loaded = false;
      axios.get(`${window.hostname}/api/admin/costo_servicio`)
        .then(response => {
          this.costos = response.data;
          this.loaded = true;
        })
        .catch(e => {
          this.loaded = true;
          console.log("Error al cargar costos servicios...", e);
        });
    },
    async post_costos_servicio() {
      if (this.modificar_costos) {
        try {
          await axios.post(`${window.hostname}/api/admin/costo_servicio`, this.costos);
          this.get_costos_servicio();
        } catch (e) {
          console.log("Error al cargar...", e);
        }
      }
    },
    toggleModificarCostosAndPost() {
      if (this.modificar_costos) {
        this.post_costos_servicio().then(() => {
          this.modificar_costos = !this.modificar_costos;
        });
      } else {
        this.modificar_costos = !this.modificar_costos;
      }
    },
    toggleModificarCostos() {
      this.modificar_costos = !this.modificar_costos;
    },
  },
  computed: {
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'prestador']),
  },
};
</script>

<style scoped>
.icon-edit {
  cursor: pointer;
}
thead td, thead th {
  font-weight: 700;
}
td, th {
  text-align: left;
}
.articulo-principal {
  min-height: calc(100vh - 120px);
  max-width: 1390px;
  margin: 0 auto;
}
.boxy {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table { 
  margin-left: auto;
  margin-right: auto;
}
.column-right {
  background-color: rgb(242, 247, 251);
  padding-top: 0px;
  padding-left: 275px;
  width: 100%;
}
.main-container {
  background-color: rgb(242, 247, 251);
  padding: 10px;
  margin-top: 10px;
}
@media (max-width: 800px) {
  .column-right {
    padding-left: 0px;
  }
}
</style>
