<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
 
  
  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="true" class="articulo-principal">
        <h2>Bienvenido</h2>    
        
        <div class="boxy">
          <div></div>
        <h2 style="margin-bottom:20px; font-size: 20px; font-weight: 700;">¡Muy buenos días! 🎉</h2>
        <p>
          Desde aquí, tendrás el control total y una visión clara de todas las operaciones críticas que mantienen a nuestra empresa a la vanguardia en servicios de exámenes de laboratorio a domicilio.


        </p>
        <b>¿Qué puedes hacer desde este panel?</b>
<br/>
<br/>
<p><b>Gestión de Tomadores de Muestra: 👩‍⚕️👨‍⚕️</b> Accede a los perfiles de todos nuestros especialistas, actualiza su información y asegúrate de que cuenten con todo lo necesario para brindar un servicio excepcional.
</p>
<p>
  <b>Manejo de Órdenes: 📋✅</b> Monitoriza, actualiza y organiza todas las solicitudes de servicios. Desde confirmar citas hasta revisar detalles específicos de cada orden, tendrás todo al alcance de un clic.

</p>
<p><b>Horarios de los Tomadores: 📆⏰</b> Planifica y visualiza las agendas de nuestros especialistas. Asegúrate de que los horarios sean óptimos para maximizar la eficiencia y la satisfacción del cliente.
</p>
<p>
  <b>Reportes y Analíticas: 📊🔍</b> Obtén insights valiosos a través de nuestra sección de reportería. Conoce las tendencias, áreas de oportunidad y el rendimiento general del servicio.

</p>
<p>
  <b>Precios y Tarifarios: 💰🔖</b> Gestiona y actualiza la estructura de precios de nuestros servicios. Mantente al tanto de las promociones y asegúrate de ofrecer siempre un precio competitivo y justo.

</p>
<p>
  <b>Y mucho más... 🚀🛠</b> Explora todas las herramientas y secciones que hemos diseñado para hacer de la administración de tu operación una experiencia intuitiva y poderosa.

</p>
<p>
  Estamos comprometidos con la excelencia y con brindar el mejor servicio posible. Tu rol es esencial para garantizar que sigamos siendo líderes en el mercado. Si tienes alguna duda o sugerencia, no dudes en ponerte en contacto con nuestro equipo de soporte.

</p>
Un saludo,
Equipo de ClinicGo
          
        </div>
      </div>

    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */

import {mapGetters, mapActions} from 'vuex';
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';

export default {
  name: 'HelloWorld',
  props:['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
  },
  data() {
    return {


    }
  },
  created(){
  

  },

  methods:{
    ...mapActions(['initialize']),



  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'prestador']),
  },



}
</script>


<style scoped>
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

