  <!-- eslint-disable -->
<template>
  <div>
    <!-- Transition for Modal Animation -->
    <transition name="zoom-m">
      <!-- Modal Container -->
      <div v-if="showing" id="modal-receta-m" class="modal-container">
        <!-- Close Icon -->
        <div @click="hide" class="contenedor-icono">
          <b-icon class="icon" icon="x"></b-icon>
        </div>

        <!-- Modal Title -->
        <h2>Gestionar Recetas</h2>

        <!-- Main Content -->
        <div class="main-m">
          <!-- Client Selection -->
          <div class="filtro">
            <h4>Seleccione Cliente</h4>
            <select v-model="selectedClienteId" @change="handleClienteChange" class="input-client">
              <option disabled value="">-- Seleccione un Cliente --</option>
              <option v-for="cliente in orden.clientes" :key="cliente.id" :value="cliente.id">
                {{ cliente.nombre }} {{ cliente.apellido1 }} ({{ cliente.rut }})
              </option>
            </select>
          </div>

          <!-- Recetas List for Selected Cliente -->
          <div v-if="selectedClienteId" class="recetas-list">
            <h4>Recetas de {{ getClienteNombre(selectedClienteId) }}</h4>
            <ul v-if="recetasPorCliente.length > 0">
              <li v-for="receta in recetasPorCliente" :key="receta.id" class="receta-item">
                <!-- Download Link -->

                <button :style="{'background-color':prestador.admin.assets.colors.primary}" @click="downloadReceta(receta.id)" class="btn-ir-gordo">
                  <b-icon icon="download"></b-icon> Descargar Receta
                </button>
                <!-- Delete Button -->
                <button @click="deleteReceta(receta.id)" class="btn btn-danger btn-sm">
                  <b-icon icon="trash"></b-icon>
                </button>
              </li>
            </ul>
            <p v-else>No hay recetas para este cliente.</p>
          </div>

          <!-- Upload New Receta for Selected Cliente -->
          <div v-if="selectedClienteId" class="upload-receta">
            <h4>Subir Nueva Receta para {{ getClienteNombre(selectedClienteId) }}</h4>
            <input
              type="file"
              @change="handleFileUpload"
              accept="image/*, application/pdf"
              class="input-file"
              ref="fileInput"
            />
            <button
              @click="uploadReceta"
              class="btn btn-primary"
              :disabled="!selectedFile || loadingUpload"
            >
              <span v-if="loadingUpload">
                <b-icon icon="spinner" spin></b-icon> Subiendo...
              </span>
              <span v-else>
                Subir Receta
              </span>
            </button>
          </div>
        </div>

        <!-- Action Buttons -->
        <button
          @click="hide"
          class="menuCol btn-ir-gordo cerrar-btn"
          :style="{ 'background-color': prestador.admin.assets.colors.primary }"
        >
          Cerrar
        </button>
      </div>
    </transition>

    <!-- Background Overlay -->
    <div v-if="showing" class="background-screen"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "ModalRecetas",
  data() {
    return {
      showing: false,          // Controls modal visibility
      orden: null,             // The entire orden object
      recetas: [],             // All recetas for the orden
      selectedClienteId: "",   // Currently selected cliente ID
      selectedFile: null,      // File selected for upload
      loadingUpload: false,    // Indicates if upload is in progress
    };
  },
  methods: {
    /**
     * Show the modal with the provided orden object and fetch existing recetas.
     * @param {Object} orden - The orden object containing all relevant data.
     */
     async downloadReceta(recetaId) {
      try {
        // Request the pre-signed URL from the backend
        const response = await axios.post(`${window.hostname}/api/admin/ver_receta_por_id`, {
          receta_id: recetaId,
        });

        const { link } = response.data;

        if (link) {
          // Open the link in a new tab
          window.open(link, '_blank');
        } else {
          alert("No se pudo obtener el enlace de descarga.");
        }
      } catch (error) {
        console.error("Error fetching receta link:", error);
        alert("Hubo un error al descargar la receta.");
      }
    },
    show(orden) {
      this.orden = orden;
      this.showing = true;
      this.fetchRecetas();
    },

    /**
     * Hide the modal and reset selections.
     */
    hide() {
      this.showing = false;
      this.orden = null;
      this.recetas = [];
      this.selectedClienteId = "";
      this.selectedFile = null;
      this.loadingUpload = false;
      this.$emit("modal-closed"); // Emit event when modal is closed
    },

    /**
     * Fetch all recetas associated with the orden.
     */
    async fetchRecetas() {
      if (this.orden.recetas && Array.isArray(this.orden.recetas)) {
        // If recetas are already provided in the orden object
        this.recetas = this.orden.recetas;
      } else {
        // Otherwise, fetch recetas from the API
        try {
          const response = await axios.get(
            `${window.hostname}/api/admin/orden/${this.orden.id}/recetas`
          );
          this.recetas = response.data;
        } catch (error) {
          console.error("Error fetching recetas:", error);
          alert("Hubo un error al obtener las recetas.");
        }
      }
    },

    /**
     * Handle the change of selected cliente.
     */
    handleClienteChange() {
      // Optionally, perform actions when a cliente is selected
      // For example, reset selectedFile
      this.selectedFile = null;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null;
      }
    },

    /**
     * Get the nombre completo of a cliente by ID.
     * @param {Number} clienteId - The ID of the cliente.
     * @returns {String} - The full name of the cliente.
     */
    getClienteNombre(clienteId) {
      const cliente = this.orden.clientes.find(c => c.id === clienteId);
      if (cliente) {
        return `${cliente.nombre} ${cliente.apellido1}`;
      }
      return "";
    },



    /**
     * Handle file selection for uploading a new receta.
     * @param {Event} event - File input change event
     */
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (["jpg", "jpeg", "png", "pdf"].includes(fileExtension)) {
          this.selectedFile = file;
        } else {
          alert("Archivo no válido. Solo se permiten imágenes o PDFs.");
          event.target.value = null;
        }
      }
    },

    /**
     * Upload the selected receta to the server for the selected cliente.
     */
    async uploadReceta() {
      if (!this.selectedFile) {
        alert("Por favor, seleccione un archivo para subir.");
        return;
      }

      if (!this.selectedClienteId) {
        alert("Por favor, seleccione un cliente para asociar la receta.");
        return;
      }

      this.loadingUpload = true; // Start loading

      const formData = new FormData();
      formData.append("file", this.selectedFile);
      // Create a Blob for the 'document' field containing orden_id and cliente_id
      const documentData = JSON.stringify({
        orden_id: this.orden.id,
        cliente_id: this.selectedClienteId,
      });
      const blob = new Blob([documentData], { type: "application/json" });
      formData.append("document", blob);

      try {
        await axios.post(`${window.hostname}/api/admin/agregar_receta`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Receta subida exitosamente.");
        await this.fetchRecetas(); // Refresh the list
        this.selectedFile = null; // Reset file input
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = null;
        }
        this.$emit("recetas-updated"); // Emit event after successful upload
      } catch (error) {
        console.error("Error uploading receta:", error);
        alert("Hubo un error al subir la receta.");
      } finally {
        this.loadingUpload = false; // Stop loading
      }
    },

    /**
     * Delete a receta by its ID.
     * @param {Number} recetaId - ID of the receta to delete
     */
    async deleteReceta(recetaId) {
      if (!confirm("¿Está seguro de que desea eliminar esta receta?")) return;

      try {
        await axios.delete(`${window.hostname}/api/admin/eliminar_receta`, {
          data: { receta_id: recetaId },
        });
        alert("Receta eliminada exitosamente.");
        await this.fetchRecetas(); // Refresh the list
        this.$emit("recetas-updated"); // Emit event after successful deletion
      } catch (error) {
        console.error("Error deleting receta:", error);
        alert("Hubo un error al eliminar la receta.");
      }
    },
  },
  computed: {
    ...mapGetters(["prestador"]), // Access to Vuex getters
    recetasPorCliente() {
      console.log(this.recetas)
      return this.recetas.filter(receta => receta.cliente_id === this.selectedClienteId);
    },
  },
};
</script>

<style scoped>
/* Modal Container Styling */
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px; /* Increased width to accommodate client selection */
  max-width: 95%;
  background: white;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  z-index: 1001;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Background Overlay */
.background-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Close Icon Styling */
.contenedor-icono {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

/* Main Content Styling */
.main-m {
  margin-top: 20px;
}

/* Filtro (Filter) Styling */
.filtro {
  margin-bottom: 20px;
}

.input-client {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Recetas List Styling */
.recetas-list {
  margin-bottom: 20px;
}

.recetas-list ul {
  list-style: none;
  padding: 0;
}

.receta-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.receta-link {
  text-decoration: none;
  color: #007bff;
}

.receta-link:hover {
  text-decoration: underline;
}

/* Upload Receta Styling */
.upload-receta .input-file {
  display: block;
  margin-bottom: 10px;
}

.upload-receta button {
  width: 100%;
}

/* Action Buttons */
.cerrar-btn {
  width: 100%;
  margin-top: 20px;
}

/* Disabled Upload Button Styling */
.btn-primary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Transition Effects */
.zoom-m-enter-active,
.zoom-m-leave-active {
  transition: all 0.3s ease;
}
.zoom-m-enter,
.zoom-m-leave-to {
  transform: scale(0.9);
  opacity: 0;
}
</style>
