var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"slide"}},[(_vm.window.width >= 800 || _vm.sideMenu)?_c('div',{staticClass:"sidenav-main"},[_c('div',{staticStyle:{"height":"80px","position":"relative"}},[_c('img',{staticStyle:{"max-height":"60px","max-width":"200px","z-index":"100003","top":"50%","position":"absolute","transform":"translateY(-50%)","left":"20px"},attrs:{"src":'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+_vm.prestador.id+'/images/logo.png',"alt":"Imagen"}})]),_c('div',{staticClass:"main-scrollable"},[_c('aside',{staticClass:"menu-vertical menu bg-menu-theme",staticStyle:{"padding-bottom":"40px","min-height":"100vh"},attrs:{"id":"layout-menu"}},[_c('div',{staticClass:"menu-inner-shadow"}),_c('ul',{staticClass:"menu-inner py-1"},_vm._l((_vm.menu_items),function(m){return (_vm.hasPermissions(m))?_c('li',{key:m.nombre,class:{
                'active': _vm.$route.name == m.nombre || m.nombres_activos.includes(_vm.$route.name),
                'open': m.viendo,
                'menu-item': !m.seccion,
                'menu-header small text-uppercase': m.seccion,
              },on:{"click":function () { if (m.to != undefined){ _vm.$router.push({name: m.to}) } else { m.viendo = !m.viendo } }}},[[(!m.seccion)?_c('a',{staticClass:"menu-link",class:{ 'active': _vm.$route.name == m.nombre, 'menu-toggle': m.subrutas != undefined },style:({
                    color: _vm.$route.name == m.nombre || m.nombres_activos.includes(_vm.$route.name) ? 'white !important' : '',
                    background: _vm.$route.name == m.nombre || m.nombres_activos.includes(_vm.$route.name)
                      ? 'linear-gradient(to right, ' + _vm.prestador.admin.assets.colors.primary + ', ' + _vm.prestador.admin.assets.colors.secondary + ')'
                      : '',
                  })},[_c('i',{staticClass:"menu-icon tf-icons ti",class:m.icono}),_c('div',{attrs:{"data-i18n":"Dashboards"}},[_vm._v(_vm._s(m.nombre))])]):_c('span',{staticClass:"menu-header-text"},[_vm._v(_vm._s(m.nombre))])],(m.viendo)?_c('ul',{staticClass:"menu-sub"},_vm._l((m.subrutas),function(sub){return (_vm.hasPermissions(sub))?_c('li',{key:sub.nombre,staticClass:"menu-item"},[_c('div',{staticClass:"menu-link",on:{"click":function () { if (sub.to != undefined){ _vm.$router.push({name: sub.to}) } }}},[_c('div',[_vm._v(_vm._s(sub.nombre))])])]):_vm._e()}),0):_vm._e()],2):_vm._e()}),0)])])]):_vm._e()]),(_vm.window.width < 800 && _vm.sideMenu)?_c('div',{staticStyle:{"position":"fixed","width":"100vw","height":"100vh","background":"rgba(0,0,0,0.4)","z-index":"9999"},on:{"click":function($event){return _vm.CambiarMenuAction({})}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }