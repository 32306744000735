<template>
  <!-- eslint-disable -->
<div  style="text-align:left; background-color: rgb(242, 247, 251);">
  <ModalAgregarHorario :profesional_id="Number($route.params.id)" ref="modalagregarhorario"  />
  <ModalModificarAtencionPrestador :profesional_id="Number($route.params.id)" ref="modalatencion"  />
  <ModalModificarProfesional  ref="modalmodificarprofesional"  />

  <div class="column-left">
    <SideNav />
  </div>

  
  <div class="column-right">
    <TopNav />
    <div class="main-container">   
      
      <div v-if="loaded" class="articulo-principal">  
        
        <div class="main-flex">

          <div class="boxy lefty-pro">
            
            <div style="width: 200px; margin-top:20px; border-radius: 100%; height: 200px; background-position: top; background-size: cover; margin: 0 auto; " :style="{
                  'background-image': profesional.atributos && profesional.atributos.hasOwnProperty('foto') ? 'url(https://assets-fotos-profesionales.s3.sa-east-1.amazonaws.com/'+profesional.atributos.foto+')' : 'url(' +require('@/assets/ia/emptyuser.webp')+ ')' ,
                  'border': '6px solid '+ (prestador.admin.assets.colors.secondary)

                  }">

                </div>
                
                <div style="text-align: center; font-weight: 700; font-size: 20px; margin-top:30px;">
                  {{ profesional.nombre }}  {{ profesional.apellido }}
                  <i v-on:click="$refs.modalmodificarprofesional.show(profesional)" :style="{'color': prestador.admin.assets.colors.primary}" class="action-icon tf-icons ti ti-pencil">
                  
                </i>
                </div>
                <hr />
                <div class="detalles">
                  <div style="color: rgba(0,0,0,0.3); font-weight: 700; margin-bottom: 10px;">DETALLES</div>
                  <div v-if="prestador.id == 1" class="detalle">
               
                    <div class="detalle-tit">Atiende a: </div>
                    <div style="display: inline-block;" class="detalle-info">
                      <div 
                      class="labels-prest" 
                      :style="{
                        'color':prestador.admin.assets.colors.secondary,
                        'border-color':prestador.admin.assets.colors.secondary
                        }" 
                      v-for="s in profesional.atiende_prestadores">{{ s.nombre }}</div>

                    </div> 

                    <button 
                    class="menuCol btn-ir-gordo"
                    style="padding: 5px 10px; margin-top:10px;"
                    v-on:click="$refs.modalatencion.show()"
                    :style="{'background-color':prestador.admin.assets.colors.primary}"> 
                    Modificar
                    <b-icon icon="pen"></b-icon>
                  </button>
                  </div>
                  <div class="detalle">
                
                    <div class="detalle-tit">Email:</div>
                    <div class="detalle-info">{{ profesional.email }}</div> 
                  </div>
                  <div class="detalle">
                    <div class="detalle-tit">Teléfono:</div>
                    <div class="detalle-info">{{ profesional.telefono == null ? 'Sin especificar':profesional.telefono }}</div> 
                  </div>
                  <div class="detalle">
                    <div class="detalle-tit">Rut:</div>
                    <div class="detalle-info">{{ profesional.rut }}</div> 
                  </div>
                  <div class="detalle">
                    <div class="detalle-tit">Tiempo asignado:</div>
                    <div class="detalle-info">{{timeConvert(profesional.horarios.reduce((x,y)=> {
                      return x + (y.prioridad == 1 ? (y.termino - y.inicio)*(y.repeticion.split("1").length - 1) : 0)
                    }, 0)) }} hrs</div> 
                  </div>
                  <div class="detalle">
                    <div class="detalle-tit">Atiende en:</div>
                    <div class="detalle-info">
                      <span v-for="c, index in profesional.comunas">
                        {{ comunas.find(x=>x.id==c).Nombre }}{{ index == profesional.comunas.length-1 ? '.' : ',' }}
                        
                      </span>
                    </div>
                  </div>
                    <div class="detalle">
                     
                    <div class="detalle-tit">Categorias:</div>
                    <div class="detalle-info">
                      <span v-for="c, index in profesional.categorias">
                        {{ categorias.find(x=>x.id==c).nombre }}{{ index == profesional.comunas.length-1 ? '.' : ',' }}
                        
                      </span>
                    </div> 
                  </div>
                </div>
              </div>
          <div class="righty-pro">
            <div class="top-menu-container">
              <div class="top-menu">
              <div
                v-on:click="viendo_top_menu = t.nombre"
                :style="{
                'background-color': viendo_top_menu==t.nombre ? prestador.admin.assets.colors.primary : '',
                'color': viendo_top_menu==t.nombre ? '#fff' : '',
                
                }" class="top-menu-item" v-for="t in top_menu">
                <i :class="t.icono" class="menu-icon tf-icons ti"></i>
                {{ t.nombre }}
              </div>
            </div>
            </div>
            <div v-if="viendo_top_menu == 'Resumen'">
            <div style="margin-bottom: 20px;" class="boxy ">
              <div class="in-title" >Próximas ordenes</div>
              <div v-for="o in profesional.ordenes.filter(x=>{return new Date() <= new Date(x.fecha) })">
               El <b>{{ new Date(o.fecha).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</b> atender a {{ o.nombre }} {{ o.apellido1 }} en {{ o.direccion }}, {{ comunas.find(x=> x.id == o.comuna_id).Nombre }}
              </div>
  
            </div>
            <div class="boxy ">
              <div class="in-title" >Actividad reciente</div>
              <div v-for="o in profesional.ordenes.filter(x=>{return new Date() >= new Date(x.fecha) })">
               El <b>{{ new Date(o.fecha).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</b> atender a {{ o.nombre }} {{ o.apellido1 }} en {{ o.direccion }}, {{ comunas.find(x=> x.id == o.comuna_id).Nombre }}
              </div>
            
            </div>
            </div>
            <div v-else-if="viendo_top_menu =='Calendario'">
              <div class="boxy">
                <div v-if="loaded_calendario">
                <div>

          <table style="width:100%; margin-bottom: 6px;">
          <td>
            <button
              :style="{'background-color':prestador.admin.assets.colors.primary}"
              class=" btn-sig"
              @click="semanaAnterior();"
            >
            <i class="tf-icons ti ti-arrow-narrow-left"></i>
             Semana Anterior
            </button>
          </td>
          <td style="text-align:center; font-weight: 700;">
            
          Semana del {{ desdeD.getDate() }} de {{ meses[desdeD.getMonth()] }} del {{ desdeD.getFullYear() }}
          </td>
          <td style="text-align:right;">
            <button
            :style="{'background-color':prestador.admin.assets.colors.primary}"
              class=" btn-sig"
              @click="semanaSiguiente()"
            >
              Semana Siguiente 
              <i class="tf-icons ti ti-arrow-narrow-right"></i>
            </button>
          </td>
        </table>
        <div
          style="padding:0px;  position:relative; margin-top:0px;"
          class="shadow-object"
        >
          <div
            v-if="!loaded_calendario"
            style="position:absolute; height: 100%; width:100%; background:rgba(0,0,0,0.4); z-index:999;"
          >
            <div style="position:absolute; top:50%; color:white; left:50%;">
              <div
                class="spinner-border spinner-border-sm"
                role="status"
              />
              Cargando...
            </div>
          </div>

          <table
            class="Table-Normal"
            style="width:100%; text-align:center;"
          >
            <td style="width:12.5%; font-size: 14px; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space:nowrap;  border:1px solid white; ">
              Horario
            </td>
            <td
              v-for="d, i in dias2"
              style="width:12.5%; font-size: 14px; overflow: hidden; text-align: center; text-overflow: ellipsis; white-space:nowrap; border:1px solid white; "
            >
              {{ d }} {{ viendoSemana[i] }}
            </td>
          </table>
                  
          <table style="width:12.5%; text-align:center; margin-left: 0px;">
            <!--display: -webkit-box-->
            <tr
              v-for="i in Math.ceil((horaMax-horaMin)/60)"
              style="text-align:center; font-size:10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-height:50px; height:50px; line-height:48px;  border-right:1px solid rgba(0,0,0,0.6); "
            >
              {{ timeConvert(horaMin+(i-1)*60) }}-{{ timeConvert(horaMin+(i)*60) }}
            </tr>
          </table>

          <div
            v-for="h in calendario.ordenes"
            v-if="new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) >= desdeD && new Date(h.fecha.a, h.fecha.m-1, h.fecha.d) <= hastaD"
            :key="h.id" 
            :style="{
              'left':(12.5*(viendoSemana.findIndex(x=>{return x == h.fecha.d})+1)).toString()+'%', 'line-height':(50*(h.hasta-h.desde)/60)+'px', 
              'height':(50*(h.hasta-h.desde)/60)+'px', 'top':(((h.desde-horaMin)*50/60)+22).toString()+'px',
              'background': h.estado == 2 ? 'repeating-linear-gradient(45deg,#606dbc,#606dbc 10px,#465298 10px,#465298 20px)': h.estado == 1 ? 'repeating-linear-gradient(45deg,#FF851B,#FF851B 10px,#fcb577 10px,#fcb577 20px)' : 'repeating-linear-gradient(45deg,#3D9970,#3D9970 10px,#549c7c 10px,#549c7c 20px)'
            }" 
            style="font-size:12px; z-index: 2; border-radius: 4px; position:absolute; vertical-align:middle; text-align:center; color:white; width:12.5%;"
          >
            Id: {{ h.id }} {{ ((viendoSemana.findIndex(x=>{return x == h.fecha.d}))) }}
          </div>
          <div style="position: absolute; top:24px;  left:0px; width: 100%; border-bottom: 1px solid rgba(0,0,0,0.3);">

</div>
          <div v-for="i in Math.ceil((horaMax-horaMin)/60)" :style="{'top': 28+(50*i)+'px'}" style="position: absolute;  left:0px; width: 100%; border-bottom: 1px solid rgba(0,0,0,0.3);">

          </div>
        </div>


                </div>
                
                </div>
                <div v-else><Loader :height="100" :width="100" /></div>
              </div>
            </div>
            <div v-else-if="viendo_top_menu =='Horarios'">
              <button
            class="menuCol btn-ir-gordo"
            :style="{'background-color':prestador.admin.assets.colors.primary}"
            style="margin-bottom: 10px;"
            @click="$refs.modalagregarhorario.show()"
          >
            Agregar Horario
            <b-icon icon="clock"></b-icon>
          </button>

              <div class="boxy">
                <div>
                  
                  <table class="horarios" style="width:100%;">
          <thead style=" font-size: 700;">
        
            <th>Días</th>
            <th> Horario</th>
   
            <th> Comunas</th>      
            <th> Categorias</th>

            <th>Intervalo</th>
            <th>Anticipación</th>
            <th>Prioridad</th>

            <th></th>
          </thead>
          <tr
            v-for="h in profesional.horarios"
            style="text-align:left;"
          >

            <td>
              <span
                v-for="r, i in h.repeticion"
                v-if="r==1"
              >{{ dias[i] }}{{ i == h.repeticion.lastIndexOf('1') ? '.':',' }}  </span> 
            </td>
            <td>
              {{ timeConvert(h.inicio) }} - {{ timeConvert(h.termino) }}
            </td>


            <td>   
              <div v-for="c in h.comunas">
                {{ comunas.filter(x=>{return x.id == c})[0].Nombre }}
              </div>
            </td>
            <td>   
              <div v-for="c in h.categorias">
                {{ categorias.filter(x=>{return x.id == c})[0].nombre }}
              </div>
            </td>

            <td>{{ h.intervalo }} min</td>
            <td>{{ timeConvert2(h.anticipacion) }}</td>
            <td>{{ h.prioridad }}</td>

            <td>
              <!-- <b-icon
                style="color:red; cursor:pointer;"
                icon="trash"
                @click="eliminar_horario(h.id)"
              /> -->
              <router-link :to="'/ModificarHorarioPro/'+$route.params.id+'/' +h.id">
                <i :style="{'color':prestador.admin.assets.colors.primary}" class="menu-icon tf-icons ti ti-pencil"></i>
              </router-link>
            </td>
          </tr>
        </table>

                </div>
              </div>
            </div>
            <div v-else-if="viendo_top_menu =='y'">

            </div>
            
          </div>

        </div>
    </div>
      <div v-else class="articulo-principal">


          <div style="height: calc(100vh - 140px); display: flex; justify-content: center; align-items: center;">


<Loader :height="100" :width="100" />

          
          </div>

        

      </div>
    </div>
  </div>



</div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import SideNav from '@/components/SideNav.vue';
import Loader from '@/components/Loader.vue';
import TopNav from '@/components/TopNav.vue';
import ModalAgregarHorario from './utils/ModalAgregarHorario.vue';
import ModalModificarProfesional from './utils/ModalModificarProfesional.vue';
import ModalModificarAtencionPrestador from './utils/ModalModificarAtencionPrestador.vue';


export default {
  name: 'Profesional',
  props:['id'],
  components: {
    SideNav,
    Loader,
    TopNav,
    ModalAgregarHorario,
    ModalModificarProfesional,
    ModalModificarAtencionPrestador
},
  data() {
    return {
      loaded:false,
      profesional:{},
      categorias:{},
      top_menu:[
        {nombre:'Resumen', icono:'ti-user-check'},
        {nombre:'Calendario', icono:'ti-calendar'},
        {nombre:'Ordenes', icono:'ti-shopping-cart'},
        {nombre:'Horarios', icono:'ti-clock'},
        {nombre:'Calificaciones', icono:'ti-star'},
      ],
      viendo_top_menu:'Resumen',


      hastaD:0,
      desdeD:0,
      loaded_calendario:false,
      calendario:{ordenes:[], bloqueos:[]},
      mapping:[6, 0, 1, 2, 3, 4, 5],
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      meses:['Enero', 'Febrero', 'Marzo', 'Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Nobiembre','Diciembre'],

    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);
    this.hoy = new Date();
    this.hoyMes = this.hoy.getMonth();
    this.hoyDia = this.hoy.getDate();  
    this.hoyAño = this.hoy.getFullYear();
    this.desdeD = new Date()
    this.desdeD.setDate(this.desdeD.getDate() - this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))
    this.hastaD = new Date()
    this.hastaD.setDate(this.hastaD.getDate() + 6-this.getDia(this.hoyAño, this.hoyMes, this.hoyDia))

    this.viendoDia = this.hoyDia;
    this.viendoMes = this.hoyMes;
    this.viendoAño = this.hoyAño
  },

  methods:{
    ...mapActions(['initialize']),
    getDia(año, mes, dia){
      return this.mapping[new Date(año, mes, dia).getDay()];

    },
    timeConvert2 (n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  + rhours + " h " + rminutes + " m.";
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    semanaSiguiente(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()+7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()+7)   
      this.hastaD = H
      this.ver_calendario();
    },
    semanaAnterior(){
      var D = new Date(this.desdeD.getFullYear(), this.desdeD.getMonth(), this.desdeD.getDate()) 
      D.setDate(D.getDate()-7)    
      this.desdeD = D
      var H = new Date(this.hastaD.getFullYear(), this.hastaD.getMonth(), this.hastaD.getDate())   
      H.setDate(H.getDate()-7)   
      this.hastaD = H
      this.ver_calendario();
    },
    ver_calendario()
    {
    if (typeof(this.$route.params.id === 'number'))
        {
          this.loaded_calendario = false;
          axios.post(window.hostname+'/api/admin/ver_calendario_pro', 
          {profesional_id: this.$route.params.id,
          dia:this.desdeD.getDate(),
          mes:this.desdeD.getMonth()+1,
          año:this.desdeD.getFullYear(),
          dia2:this.hastaD.getDate(),
          mes2:this.hastaD.getMonth()+1,
          año2:this.hastaD.getFullYear(),
          })
          .then(response => 
          {
              this.loaded_calendario = true;
              this.calendario.ordenes = response.data.ordenes
              //this.categorias = response.data.categorias
          })

          .catch(e=>{
              this.loaded = true;
              console.log("Error al cargar...")
          })
        }
      },
      async initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.profesional = {}
        this.categorias = {}
        this.loaded = false;
        axios.post(window.hostname+'/api/admin/init_modificar_pro', 
        {id: pid})
        .then(response => 
        {
            
            this.profesional = response.data.profesional
            this.categorias = response.data.categorias
            this.$forceUpdate();
            this.loaded = true;
        })

        .catch(e=>{
            
            console.log("Error al cargar...")
            this.loaded = true;
        })
      }
    },
  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'prestador']),
    viendoSemana: function(){
      var o;
      var f = [new Date(this.desdeD.getTime())];
      var ret = [this.desdeD.getDate()]
      for (var i = 1; i < 7; i++)
      {
        o = new Date(f[i-1].getTime())
        o.setDate(o.getDate() + 1)
        f.push(o)
        ret.push(o.getDate())
      }
      
      return ret;
     
    },
    horaMin: function(){
      var min = 360;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde < min)
          {
            min = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return min
    },

    horaMax: function(){
      var max = 1080;
      for(var i = 0; i<this.profesional.horarios.length; i++)
      {
        for(var j = 0; j < this.profesional.horarios[i].ordenes.length; j++)
        {
          if (this.profesional.horarios[i].ordenes[j].desde > max)
          {
            max = this.profesional.horarios[i].ordenes[j].desde
          }
        }

      }
      return max
    },
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
    this.$root.$on('recargar_profesionales', async ()  =>  {
      await this.initialize_(this.$route.params.id);
      
      
});
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    viendo_top_menu(val){
      if(val == "Calendario"){
        this.ver_calendario()
      }


    },
  }

}
</script>


<style scoped>
.labels-prest{
  display: inline-block;
  border-radius: 10px;
  
  padding: 0px 8px;
  color: #fff;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  border: 1px solid;
}
.horarios td{
  padding: 8px;
  border-bottom: 1px solid rgba(0,0,0,0.1);

}
.ti-pencil:hover{
  opacity: 0.8;
}
.horarios th{
  font-weight: 700;
  padding: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  text-transform: uppercase;
}
.horarios{
  border-radius: 4px;
}
.btn-sig{
  border: none;
  padding: 4px 10px;
  border-radius: 4px;
  color:#fff;
}
.btn-sig:hover{
  opacity: 0.8;
}
td{
  text-align: left;
}
.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  
  padding:2px;
}
table
{ 

}
.in-title{
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px;
  color: rgba(0,0,0,0.4);

}
.top-menu-container{
  padding: 0px 10px;
  margin-bottom: 20px;
  
}
.top-menu{
  display: flex;
  justify-content: space-between;

}
.top-menu-item{
  text-align: center;
  padding: 6px 15px;
  border-radius: 4px;
  cursor: pointer;

}
.top-menu-item:hover{
  background-color: rgba(0,0,0,0.04);

}
.detalle{
  margin: 20px 0px;

}
.detalles{
  margin-top: 30px;
}
.detalle-tit{
  font-weight: 600;
  display: inline-block;
  margin-right: 6px;
}
.detalle-info{
  display: inline-block;
}
td{
  text-align: left;
}
.articulo-principal{
 min-height: Calc(100vh - 120px);
  max-width:1390px;



  
  margin: 0 auto;




}
.main-flex{
  display: flex;
  align-items: flex-start; 

}
.lefty-pro{
  min-width: 400px;
  width:400px;
  background-color: red;
  margin-right:20px;

}
.righty-pro{
  flex-grow: 1;

}
.boxy{
  background-color: white;
  padding: 20px;
  box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
  border-radius: 8px;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}
.column-right{
  background-color: rgb(242, 247, 251);

  padding-top:0px;
  padding-left:275px;
  width:100%;
  

}
.main-container{
  background-color: rgb(242, 247, 251);
  padding: 10px;

  margin-top:10px;

}
@media (max-width: 800px) {
 .column-right{

  padding-left:0px;


}
}
</style>

